$primary: hsla(49, 100%, 60%, 1);
$secondary: hsla(250, 100%, 72%, 1);
$secondary-light: hsl(248, 66%, 83%);
$success: hsla(148, 55%, 53%, 1);
$info: hsla(243, 52%, 68%, 1);
$warning: hsla(30, 95%, 55%, 1);
$danger: hsla(358, 59%, 45%, 1);
$light: hsla(210, 20%, 98%, 1);
$light-gray: hsla(239, 14%, 95%, 1);
$white: hsla(0, 0%, 100%, 1);
$dark: rgb(54, 54, 54);
$red: hsla(358, 59%, 45%, 1);
$black: black;
$green: hsla(148, 55%, 53%, 1);
$blue: hsla(204, 86%, 53%, 1);
$yellow: hsla(49, 100%, 60%, 1);
$purple: hsla(250, 100%, 72%, 1);
$teal: hsla(197, 100%, 47%, 1);
$indigo: hsla(231, 73%, 57%, 1);
$pink: hsla(326, 100%, 73%, 1);
$cyan: hsla(187, 100%, 50%, 1);
$gray-100: hsla(0, 0%, 98%, 1);
$gray-200: hsla(0, 0%, 96%, 1);
$gray-300: hsla(0, 0%, 93%, 1);
$gray-400: hsla(0, 0%, 89%, 1);
$gray-500: hsla(0, 0%, 86%, 1);
$gray-600: hsla(0, 0%, 82%, 1);
$gray-700: hsla(0, 0%, 71%, 1);
$gray-800: hsla(0, 0%, 48%, 1);
$gray-900: hsla(0, 0%, 10%, 1);
$ic-primary-color: hsla(250, 100%, 72%, 1); // your primary color
$ic-primary-color-light: lighten($ic-primary-color, 5%); // lighter version
$ic-primary-color-dark: darken($ic-primary-color, 5%); // darker version
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "light-gray": $light-gray,
  "dark": $dark,
  "red": $red,
  "green": $green,
  "blue": $blue,
  "yellow": $yellow,
  "purple": $purple,
  "teal": $teal,
  "indigo": $indigo,
  "pink": $pink,
  "cyan": $cyan,
  "white": $white,
  "gray": $gray-900,
  "gray-100": $gray-100,
);

$border-radius: 0.25rem;
$spacer: 1rem;

$sizes: (
  "w-10": 10%,
);

$prefix: "bs-";

$font-family-sans-serif: -apple-system, system-ui, BlinkMacSystemFont,
  "Source Sans Pro", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$font-family-serif: Georgia, "Times New Roman", Times, serif !default;
$font-family-monospace: Menlo, Monaco, Consolas, "Liberation Mono",
  "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;

// stylelint-disable value-keyword-case
$font-family-sans-serif:
  system-ui,
  -apple-system,
  "Segoe UI",
  Roboto,
  "Helvetica Neue",
  "Noto Sans",
  "Liberation Sans",
  Arial,
  sans-serif,
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol",
  "Noto Color Emoji";
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
  "Liberation Mono", "Courier New", monospace;
// stylelint-enable value-keyword-case
$font-family-base: var(--#{$prefix}font-sans-serif);
$font-family-code: var(--#{$prefix}font-monospace);

// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
$font-size-root: null;
$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-sm: $font-size-base * 0.875;
$font-size-lg: $font-size-base * 1.25;

$font-weight-lightest: 100;
$font-weight-lighter: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-bolder: 800;
$font-weight-boldest: 900;


$font-weight-base: $font-weight-normal;

$line-height-base: 1.5;
$line-height-sm: 1.25;
$line-height-lg: 2;

$h1-font-size: $font-size-base * 2.25;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base * 1.1;
$h7-font-size: $font-size-base * 1;
$h8-font-size: $font-size-base * 0.75;
$h9-font-size: $font-size-base * 0.5;

$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  7: $h7-font-size,
  8: $h8-font-size,
  9: $h9-font-size
);

$tooltip-font-size: $font-size-sm;
$tooltip-max-width: 350px;
$tooltip-color: $white;
$tooltip-bg: $black;
$tooltip-border-radius: $border-radius;
$tooltip-opacity: 0.9;
$tooltip-padding-y: $spacer * 0.5;
$tooltip-padding-x: $spacer * 1;
$tooltip-margin: null; // TODO: remove this in v6

$tooltip-arrow-width: 0.8rem;
$tooltip-arrow-height: 0.4rem;
// fusv-disable
$tooltip-arrow-color: null; // Deprecated in Bootstrap 5.2.0 for CSS variables
// fusv-enable

@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
@import "../node_modules/bootstrap/scss/bootstrap";

.btn-primary {
  color: #000;
  font-weight: 500;
}

.btn-secondary {
  color: #fff;
  font-weight: 500;
}

.btn-secondary:hover,
.btn-outline-secondary:hover, .btn-secondary:disabled {
  color: white;
}

.btn-info:hover {
  color: white;
}

html,
body {
  height: 100%;
  margin: 0;
  background-color: transparent;
}

.App {
  text-align: center;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  //margin-top: 30px;
}

.App-logo {
  pointer-events: none;
}

p {
  max-width: 600px;
  margin: 1em auto;
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

// h3 {
//   margin: 40px 0 0;
// }

// h1 {
//   color: #2c3e50;
// }

.active {
  color: #42b983;
}
.inactive {
  color: #fe5750;
}

.hidden {
  display: none;
}

code {
  background: #f1f1f1;
  padding: 0.2em;
  color: #fe5750;
  font-size: 13px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #fe5750;
  padding-left: 4px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// ArtistAI GPT

.iframe-mode {
  max-width: 100% !important;
  padding: 0 !important;
  //margin: 0 !important;
}

.background-container {
  width: 100%;
  padding: 20px 0;
}

.background-container.assistant {
  background-color: #f5f5f5;
}

.message-container {
  max-width: 600px;
  margin: 0 auto 10px;
}

.message-row {
  justify-content: center;
}

.chat-widget-container {
  // background-color: #f9fafb;
  height: 100vh;
  //margin-top: -40px;
  padding-top: 40px;
}

.chat-message {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
}

.chat-message.ai-message {
  background-color: #e2e2e2;
}

.chat-message.ai-message.dark {
  background-color: #c2c2c2;
}

.message-title {
  display: block;
}

.message-content {
  text-align: left;
}

.chat-message p {
  margin: 0 auto;
}

.input-container {
  padding-top: 20px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-box {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  padding-right: 40px;
  display: flex;
  align-items: center;
}

.input-text-wrapper {
  min-height: 50px;
  display: flex;
  flex-grow: 1;
  position: relative;
  padding: 0.75rem 1rem;
  width: 100%;
  overflow: auto;
  max-height: 225px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  background-color: white;
}

.input-text {
  border: none;
  resize: none;
  display: flex;
  align-items: center;
}

.input-text:focus {
  outline: none;
  box-shadow: none;
}

// .input-text-wrapper:focus-within {
//   //border-color: hsla(49, 100%, 60%, 1);
//   //box-shadow: 0 0 0 8px hsla(49, 100%, 60%, 0.25);
// }

.submit-button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
}

.send-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 1.2em;
  color: #6c757d;
}

.form-container {
  margin-top: 15px;
  position: fixed;
  bottom: 35px;
  width: 60%;
  left: 50%;
  transform: translateX(-50%);
}

.logo {
  display: block;
  max-width: 100%;
  margin: 20px auto;
  width: 65px;
  height: 65px;
}

.logo-ad-creator {
  display: block;
  max-width: 100%;
  margin: 20px auto;
  width: 50px;
  height: 50px;
}

.small {
  transform: (scale(0.5));
}

.smaller {
  transform: (scale(0.25));
}

.smallest {
  transform: (scale(0.15));
}

.faded-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-55%, -50%);
  opacity: 0.2;
  pointer-events: none;
  transition: opacity 0.3s;
  //width: 100px;
  height: 100px;
}

@media (max-width: 768px) {
  .faded-logo {
    height: 80px;
  }
}

@media (max-width: 576px) {
  .faded-logo {
    height: 60px;
  }
}

.hidden {
  opacity: 0;
}

// iFrame styling

.iframe-configurator {
  max-width: 500px;
  margin: 0 auto;

  .generated-iframe {
    margin-top: 1rem;
    border: 1px solid #ccc;
    padding: 1rem;
    background-color: #f8f9fa;
    border-radius: 4px;

    pre {
      margin: 0;
      white-space: pre-wrap;
      word-wrap: break-word;
    }

    button {
      margin-top: 0.5rem;
    }
  }
}

.iframe-preview {
  width: 75%;
  margin: 0 auto;
}

.FormGroup {
  padding-bottom: 15px;
}

h3.mb-3 {
  margin-bottom: 1rem;
}

.highlight {
  background-color: hsla(250, 100%, 72%, 0.099);
  cursor: pointer;
}

.highlight:hover {
  background-color: hsla(250, 100%, 72%, 0.25);
}

// ArtistAI Engage

.engage-chat-widget-container {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 70vh;
}

.chat-conversation {
  overflow: auto;
  max-height: calc(100vh - 60px);
  padding: 10px;
}

.chat-message-item {
  border: none;
}

.chat-message-item.user {
  display: flex;
  justify-content: flex-end;
}

.chat-bubble {
  max-width: 60%;
  padding: 10px;
  border-radius: 18px;
  margin: 5px 0;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  position: relative; /* Setup a relative container for our psuedo elements */
  // max-width: 255px;
  margin-bottom: 15px;
  padding: 10px 20px;
  line-height: 24px;

  &:before {
    width: 20px;
  }

  &:after {
    width: 26px;
    background-color: white; /* All tails have the same bg cutout */
  }

  &:before,
  &:after {
    position: absolute;
    bottom: 0;
    height: 25px; /* height of our bubble "tail" - should match the border-radius above */
    content: "";
  }
}

.chat-bubble.user {
  background-color: #0b93f6;
  color: #fff;
  align-self: flex-end;

  &:before {
    right: -7px;
    background-color: #0b93f6;
    border-bottom-left-radius: 16px 14px;
  }

  &:after {
    right: -26px;
    border-bottom-left-radius: 10px;
  }
}

.chat-bubble.assistant {
  background-color: #f0f0f0;
  color: #000;
  align-self: flex-start;

  &:before {
    left: -7px;
    background-color: #f0f0f0;
    border-bottom-right-radius: 16px 14px;
  }

  &:after {
    left: -26px;
    border-bottom-right-radius: 10px;
  }
}

.chat-form {
  margin-top: auto;
}

.chat-input {
  flex: 1;
  margin-right: 10px;
  border-radius: 18px;
  border: 1px solid lightgray;
  padding: 10px;
}

.chat-button {
  border-radius: 50%;
}

.activeToggle {
  color: #ebe7ff !important;
}

.typing {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5em;
  height: 2em;
  position: relative;
  padding: 10px;
  margin-left: 5px;
  background: #e6e6e6;
  border-radius: 20px;
}

.typing__dot {
  float: left;
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background: #8d8c91;
  border-radius: 50%;
  opacity: 0;
  animation: loadingFade 1s infinite;
}

.typing__dot:nth-child(1) {
  animation-delay: 0s;
}

.typing__dot:nth-child(2) {
  animation-delay: 0.2s;
}

.typing__dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loadingFade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}

.custom-select {
  appearance: none;
  background-image: url('data:image/svg+xml;charset=UTF8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 5"><path fill="%232C3037" d="M2 0L0 2h4zm0 5L0 3h4z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 8px 10px;
}

// Funnel Accelerator

.header-font {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 20px; // Adjust as per your preference
}

.title-font {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px; // Adjust as per your preference
}

// Onboarding Form

.checked-primary {
  input[type="checkbox"]:disabled {
    &:not(:checked) {
      &:after {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        background-color: $secondary;
        border-radius: 3px;
      }
    }
  }
}

.subgenre-column {
  flex: 1;
}

.custom-back-button {
  background-color: #f9fafb;
  border: 1px solid #e0e0e0;
  color: #333;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition:
    background-color 0.2s,
    color 0.2s;

  display: flex;
  align-items: center;
}

.custom-stepper-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.custom-next-stepper {
  background-color: #f9fafb;
  border: 1px solid #e0e0e0;
  color: #333;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition:
    background-color 0.2s,
    color 0.2s;

  display: flex;
  align-items: center;
}

.custom-back-stepper {
  background-color: #f9fafb;
  border: 1px solid #e0e0e0;
  color: #333;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition:
    background-color 0.2s,
    color 0.2s;

  display: flex;
  align-items: center;
}

.custom-back-stepper:hover,
.custom-next-stepper:hover {
  background-color: #333;
  color: #fff;
}

.custom-back-button i {
  margin-right: 10px;
}

.custom-back-button:hover {
  background-color: #333;
  color: #fff;
}

.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.video-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.stepper {
  display: flex;
  align-items: center;
}

.step {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  // padding: 10px 20px;
  // border-radius: 10px;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex-direction: column;
}

.step-line {
  flex-grow: 1;
  border-top: 2px solid #e0e0e0;
  margin: 0 10px;
  height: 0;
}

.step-number {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e0e0e0;
  color: #333;
  font-weight: bold;
}

.step.active .step-number {
  background-color: $secondary; /* Bootstrap primary color */
  color: #fff;
}

.step-label {
  font-weight: 500;
  font-size: 0.75em;
}

.drop-shadow {
  filter: drop-shadow(0 4px 9px rgba(0, 0, 0, 0.2));
}

.datepicker-custom-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.datepicker-calendar-icon {
  margin-left: 10px;
}


// MusicHub styling

.music-hub-page-container {
    height: 100vh;
}

.background-image-blur {
  position:absolute;
  backdrop-filter: blur(100px);
  height:100%;
  width:100%;
  z-index:-1;
}



.music-hub-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 1));

  .music-hub-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    // background-color: rgba(0,0,0,0.7);
    border-radius: 20px;
    color: white;

    .artist-image {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      overflow: hidden;
      // margin-bottom: 20px;


    }

    .music-links {
    display: flex;
    flex-direction: column;
    gap: 10px;

      button {
        margin: 0 10px;
      }
    }

    .social-icons {
      display: flex;
      justify-content: center;

      a {
        color: white;
        margin: 0 10px;
        font-size: 24px;
      }
    }
  }
}

.music-hub-container {
  .music-hub-card {
    background-color: hsla(0, 0%, 96%, 0.09);
    max-width: 480px;
    margin: 1em;
    border-radius: 12px;

      .artist-profile-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

    .music-links button {
      margin: 5px 0;
    }

    .social-icons {
      margin: 20px 0;

      a {
        color: $light;
        margin: 0 10px;
      }
    }
  }
}

// Soundboard

.holographic-token {
  background: linear-gradient(40deg, $ic-primary-color, $ic-primary-color-light, $ic-primary-color-dark);
  background-size: 600% 600%;
  -webkit-animation: Gradient 45s ease infinite; /* Increase animation duration for slower effect */
  -moz-animation: Gradient 45s ease infinite; /* Increase animation duration for slower effect */
  animation: Gradient 45s ease infinite; /* Increase animation duration for slower effect */
  color: white;
  border-radius: 5px;
  padding: 3px 8px; /* Decrease padding for smaller token display */
  display: inline-block;
  font-size: 0.75em; /* Reduce font size to 75% of the parent */
  // margin-top: 5px; /* Add top margin to ensure tokens display on a new line */
  // width: 100%; /* Ensuring the span takes up the full width of its container */
}

@-webkit-keyframes Gradient {
  0% { background-position: 0% 50% }
  50% { background-position: 100% 50% }
  100% { background-position: 0% 50% }
}

@-moz-keyframes Gradient {
  0% { background-position: 0% 50% }
  50% { background-position: 100% 50% }
  100% { background-position: 0% 50% }
}

@keyframes Gradient {
  0% { background-position: 0% 50% }
  50% { background-position: 100% 50% }
  100% { background-position: 0% 50% }
}

.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.request-title {
  margin: 0; // Remove default margins for h5
}

.request-description {
  margin-top: 5px; // Less padding between description and title
}

.upvote-button {
  margin-bottom: 20px; // Add some space between button and contributors
}

.contributors-section {
  border: 1px solid #ddd; // Add a border around the contributors
  padding: 10px; // Add some padding inside the border
}

.contributor-comment {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px; // Space between comments
}

.contributor-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.title-token {
  font-size: 0.6em; // Smaller token count in title
}

.avatar-img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center; /* This will help keep the focus on the center */
}


