.profile-container {
  font-family: "system-ui", sans-serif;
  /* max-width: 600px; */
  margin: auto;
  background-color: rgba(0, 0, 0, 0);
  /* padding: 20px; */
  border-radius: 15px;
  /* width: 100%; */
  height: 100%;
  box-sizing: border-box;
}

.profile-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: auto;
  display: block;
  object-fit: cover; /* Ensures the image maintains aspect ratio */
}

.hub-h1 {
  color: white;
  font-size: 1.5em;
  /* margin: 10px 0; */
}

.hub-p {
  color: #aaa;
  font-size: 1.2em;
  /* margin: 10px 0; */
}

.edit-profile-link {
  color: #888;
  text-decoration: none;
  font-size: 0.9em;
}

.share-btn,
.enter-community-btn {
  width: 100%;
  /* margin: 10px 0; */
}
