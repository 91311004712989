.artist-id-form-container {
  max-width: 500px;
  margin: auto;
  padding: 20px;
}

.artist-id-form {
  background: #f7f7f7;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.artist-id-form .form-group label {
  font-size: 14px;
  color: #333;
}

.artist-id-form .form-control {
  border-radius: 5px;
  border: 1px solid #ddd;
  box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
}

.artist-id-form button {
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  width: 100%;
  margin-top: 10px;
}
