.transparent-theme {
  background-color: transparent;
  color: white;
}
.streamProgressBar {
  background-color: #333; /* Dark color for unplayed part */
  height: 3px;
  position: relative;
  border-radius: 4px;
}

.streamProgressBar .progress-bar {
  background-color: red; /* To see the played part easily */
}

.current-time-indicator {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%); /* Center it on its position */
  width: 12px;
  height: 12px;
  background-color: red;
  border-radius: 50%;
  z-index: 2; /* Ensure it's above the progress bar */
}


.full-icon {
  height: 100%;
  width: 100%;
}

.content-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 280px;
}

.rounded-dark-theme {
  border-radius: 12px;
  background-color: #282828;
  /* margin-top: 10px; */
  /* margin-bottom: 10px; */
  padding-bottom: 20px;
  padding-top: 20px;
  position: relative;
}

.overlay {
  position: absolute;
  border-radius: 12px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.lock-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 25%;
  width: 25%;
  /* your lock icon styles here */
}

h1.content-player {
  color: white;
}

h2.content-player {
  color: #a3a3a3;
  /* font-size: medium; */
}

span.content-player {
  color: #a3a3a3;
}

/* Mobile first: Default styles */

.track-title {
  font-size: 1rem;
}

.track-artist {
  font-size: 0.8rem;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .track-title {
    font-size: 1.2rem;
  }

  .track-artist {
    font-size: 0.9rem;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .track-title {
    font-size: 1.5rem;
  }

  .track-artist {
    font-size: 1rem;
  }
}

/* Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .track-title {
    font-size: 1.7rem;
  }

  .track-artist {
    font-size: 1.2rem;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
/* @media (min-width: 1200px) {
  .track-title {
    font-size: 2rem;
  }

  .track-artist {
    font-size: 1.5rem;
  }
} */ 
