/* Fan Map */
/* Style for the container of the custom control buttons */
.fan-map-style-options {
  margin: 10px 0px 0px 0px;
  padding: 6px 5px;
  border-radius: 2px;
  z-index: 1;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}

/* Style for each individual button */
.fan-map-style-options button {
  border: 1px solid #000000;
  border-radius: 2px;
  cursor: pointer;
  margin: 2px 0px;
  width: 15px;
  height: 10px;
}

.fan-map-style-options button.fan-map-button1 {
  background-color: #8b5cf6;
  border-color: #190f2f;
}

.fan-map-style-options button.fan-map-button1:hover {
  background-color: #190f2f;
  border-color: #8b5cf6;
}

.fan-map-style-options button.fan-map-buttonX {
  background-color: #8ab4f8;
  border-color: #bbe2c6;
}

.fan-map-style-options button.fan-map-buttonX:hover {
  background-color: #bbe2c6;
  border-color: #8ab4f8;
}

.fan-map-style-options-manual label {
  margin: 0px 5px;
}

.fan-map-style-options-manual label input[type="checkbox"] {
  margin: 0px 2px 0px 0px;
  cursor: pointer;
}

/* Style for the hover state of the buttons */
.fan-map-style-options button:hover {
  box-shadow: 0 0 10px 5px rgba(0, 0, 255, 0.5);
}

/* Style for the active state of the buttons */
.fan-map-style-options button.active {
  background-color: #c0c0c0;
}

/* our own custom control */
.map-radius-tally {
  font-size: 12px;
  width: 215px;
}

.map-radius-tally h5 {
  font-weight: 700;
  margin: 0px;
  text-align: center;
}

.map-radius-tally-inner {
  border: 1px dashed #8b5cf6;
  border-radius: 5px;
  padding: 5px;
}

.map-radius-tally div {
  margin: 5px 0px;
}

.map-reset-radius button {
  background-color: #e2e2e2;
  padding: 1px 2px;
  font-size: 8px;
  border-color: #8b5cf6;
  border-radius: 2px;
  height: 15px;
  vertical-align: bottom;
  margin-left: 5px;
}

.map-reset-radius button:hover {
  background-color: #8b5cf6;
  border-color: #ffffff;
  color: #ffffff;
}

.map-radius-tally input[type="text"] {
  width: 160px;
}

.map-radius-tally form button {
  background-color: #8b5cf6;
  border-color: #190f2f;
  border-radius: 20px;
  margin: 0 0 0 2px;
}

.map-radius-tally form button:hover {
  background-color: #8b5cf6AA;
  border-color: #8b5cf6;
}

.radius-input-container input[type="number"] {
  width: 40px;
  margin-right: 4px;
  height: 15px;
  font-size: 10px;
}

.radius-input-container label {
  margin: 0px 2px 0px 4px;
  font-size: 10px;
}

.addtagfanmap ::before {
  content: "\2705";
  padding-right: 5px;
}

.addtagfanmap button {
  width: 100%;
  padding: 1px;
  font-size: 14px;
}

.gm-style-iw p {
  margin: 2px 0;
  font-size: 10px;
}

.gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
  bottom: 217px !important;
}

button.gm-svpc {
  top: 22px !important;
}

/* tally below map */
.tally-count-div {
  background-color: white;
  border: 1px solid #cccccc;
  padding: 10px;
  margin: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.tally-count-div-inner {
  padding: 10px;
}

.tally-count-div h3 {
  margin: 0 0 10px;
  text-align: center;
}

.tally-count-div h4 {
  font-weight: bold;
  margin-top: 10px;
  float: left;
}

.tally-count-div ul {
  list-style-type: "\1F3AF";
  margin: 10px;
  padding: 0 5px;
}

.tally-count-div .citytally ul {
  list-style-type: "\1F6A9";
}

.tally-count-div li {
  margin: 5px;
  padding: 2px 5px;
}

/* Target even (alternate) list items and give them a different background color */
.tally-count-div li:nth-child(even) {
  background-color: #f2f2f2;
}

.fancount {
  border: 1px solid #cccccc;
  border-radius: 5px;
  color: green;
  padding: 2px 5px;
}

.high-fives {
  padding: 0px 5px;
}

.radiuscount {
  border: 2px dotted #559944;
  border-radius: 50%;
  color: red;
  height: 40px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  padding: 1px 15px;
}

.radius-marker-styling {
  cursor: sw-resize !important;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border: 5px;
  padding: 5px;
  position: relative;
  top: -20px;
  left: -0px;
}

.radius-marker-styling:hover {
  cursor: sw-resize !important;
}

.tabletop {
  font-weight: bold;
  margin: 5px 0;
  border-bottom: 1px dashed #cccccc;
}

.tablezip {
  text-align: left;
  float: left;
  width: 200px;
}

.tabletop .tablezip {
  margin: 0px 25px 0px 0px;
  padding: 5px 0px 0px 0px;
}

.citytable .tablezip:hover {
  background-color: #8b5cf6;
  cursor: pointer;
}

.tablecount {
  text-align: left;
  float: left;
  border-left: 1px solid #cccccc;
  padding: 5px 0px 0px 5px;
  width: 100px;
}

.tablecount-sort {
  text-align: left;
  float: left;
  border-left: 1px solid #cccccc;
  padding-left: 5px;
  width: 300px;
}

select#sortOption2 {
  width: 130px;
  cursor: pointer;
}

.tabletop div:hover {
  background-color: #8b5cf6;
  cursor: pointer;
}

.tabletop div.tablecount-sort:hover {
  background-color: unset;
  cursor: unset;
}

.tabletop div.tablecount-sort .tablesort:hover {
  background-color: unset;
  cursor: unset;
}

.tabletop div.tablecount-sort .tablesort .sort-container:hover {
  background-color: unset;
  cursor: unset;
}

.tablesort {
  text-align: left;
  float: right;
  padding: 0 0 5px 10px;
}

.citytable .tablesort {
  float: none;
}

.clear {
  display: block;
  clear: both;
}

.sort-container {
  display: flex;
  align-items: center;
}

.sort-container label {
  margin-right: 10px;
  font-weight: bold;
}

.sort-container select {
  padding: 5px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  font-size: 14px;
  min-width: 100px;
  cursor: pointer;
}

.sort-container select option {
  font-size: 14px;
  cursor: pointer;
}

.sort-container select:focus {
  outline: none;
  border-color: #8b5cf6;
}

.spacer {
  display: block;
  height: 40px;
}

.fan-map-return-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #3a1f72;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.fan-map-return-to-top.show {
  opacity: 1;
}

.map-toggle-div {
  display: grid;
}

.map-toggle-button {
  background-color: #8b5cf6;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  top: 10px;
  right: 10px;
  float: inline-end;
  transition: background-color 0.3s ease;
}

.map-toggle-button:hover {
  background-color: #3a1f72;
}

.fanmap-refresh {
  background-color: transparent;
  border:none;
  color:#3a1f72;
}
.map-radius-tally-inner .fanmap-refresh {
  float: right;
}